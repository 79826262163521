import $ from 'jquery'
import '@popperjs/core'
import 'bootstrap/js/src/collapse'
import 'bootstrap/js/src/dropdown'

(($) => {
  'use strict'

  const hashChange = function () {
    let container = $(location.hash)
    $('video', container).each(function () {
      this.play()
    })
  }
  

  $(window).on('hashchange', hashChange)
  $('a.close').click(function () {
    const container = $(this).parent('.overlay'),
      video = container.find('video')
    video.each(function () {
      this.pause()
      this.currentTime = 0 //rewind
    })
  })
  hashChange()
})($)
